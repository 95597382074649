import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { User } from "./user.model";
import { environment } from "../../environments/environment"

export interface AuthResponseData{
  idToken: string,
  email: string,
  refreshToken: string,
  expiresIn: string,
  localId: string,
  registered?: boolean
}

@Injectable({providedIn: 'root'})

export class AuthService {
  user = new BehaviorSubject<User>(null)//new Subject<User>(). Behaviour Subject gives subscribers access to latest data before has been suscribed
  private tokenExpirationTimer: any

  constructor(private http: HttpClient, private router: Router) { }
  
  signUp(email: string, password: string){
    const url = 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=' + environment.firebaseAPIkey
    const request = {
      email: email,
      password: password,
      returnSecureToken: true
    }
    return this.http.post<AuthResponseData>(url,request).pipe(catchError(this.handleError),
      tap(responseData => {
        this.handleAuthorization(responseData.email, responseData.localId, responseData.idToken, +responseData.expiresIn)
      }))
  }

  logIn(email: string, password: string) {
    const url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + environment.firebaseAPIkey
    const request = {
      email: email,
      password: password,
      returnSecureToken: true
    }
    return this.http.post<AuthResponseData>(url,request).pipe(catchError(this.handleError),
    tap(responseData => {
      this.handleAuthorization(responseData.email, responseData.localId, responseData.idToken, +responseData.expiresIn)
    }))
  }

  autoLogin() {
    const userData:{
      email: string
      id: string
      _token: string
      _tokenExpirationDate: string
    } = JSON.parse(localStorage.getItem('userData'))
    if (!userData) {
      return
    }

    const loadedUser = new User(userData.email, userData.id, userData._token, new Date(userData._tokenExpirationDate))

    if (loadedUser.token) {
      this.user.next(loadedUser)
      const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime()
      this.autoLogout(expirationDuration)
    }

  }

  logOut() {
    this.user.next(null)
    this.router.navigate(['/auth'])
    localStorage.removeItem('userData')
    if (this.tokenExpirationTimer){
      clearTimeout(this.tokenExpirationTimer)
    }
    this.tokenExpirationTimer = null
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logOut()
    }, expirationDuration)
  }

  private handleAuthorization(email: string, localId: string, idToken: string, expiresIn: number) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000)
    const user = new User(email, localId, idToken, expirationDate)
    this.user.next(user)
    this.autoLogout(expiresIn * 1000)
    localStorage.setItem('userData', JSON.stringify(user))
  }

  private handleError(errorResponse: HttpErrorResponse) {
  
    let errorMessage = 'An error ocurred'
    if(!errorResponse.error || !errorResponse.error.error) {
      return throwError(errorMessage)
    }
    switch (errorResponse.error.error.message) {
      case 'EMAIL_EXISTS':
      errorMessage = 'The email address is already in use by another account.'
      break
      case 'EMAIL_NOT_FOUND':
      errorMessage = 'There is no user record corresponding to this identifier.'
      break
      case 'INVALID_PASSWORD':
      errorMessage = 'The password is invalid.'
      break
    }
    return throwError(errorMessage)
  }

}