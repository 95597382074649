import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Ingredient } from "../shared/ingredient.model";
import { Recipe } from "./recipe.model";

@Injectable({providedIn: 'root'})

export class RecipeService{
   recipesChanged = new Subject<Recipe[]>()
   private recipes: Recipe[] = []
   
   // private recipes: Recipe[] = [
   //    new Recipe('Test Recipe', 'This is the description of the recipe',
   //    'https://recetasfacil.online/wp-content/uploads/2018/12/Recetas-fáciles-de-pollo-para-niños-e1544662062923.jpg',
   //    [
   //       new Ingredient('Meat',1),
   //       new Ingredient('Fries',20)
   //    ]  ),
   //    new Recipe('Recipe two', 'This is ANOTHER the description of the recipe',
   //    'https://cdn2.cocinadelirante.com/sites/default/files/styles/gallerie/public/images/2018/09/recetas-con-verduras-para-cenar-faciles-ligeras-rapidas-baratas.jpg',
   //     [
   //        new Ingredient('Bread',2),
   //        new Ingredient('Cheese',4)
   //     ])
   // ]

   setRecipes(recipes: Recipe[]) {
      this.recipes = recipes
      this.updateRecipesChanged()
   }

   getRecipes(){
      return this.recipes.slice()
   }

   getRecipe(index: number){
      return this.recipes[index]
   }

   addRecipe(recipe: Recipe) {
      this.recipes.push(recipe)
      this.updateRecipesChanged()
   }

   updateRecipe(index: number, newRecipe: Recipe) {
      this.recipes[index] = newRecipe
      this.updateRecipesChanged()
   }

   deleteRecipe(index: number){
      this.recipes.splice(index ,1)
      this.updateRecipesChanged()
   }

   updateRecipesChanged(){
      this.recipesChanged.next(this.recipes.slice())
   }

}