import { Directive, ElementRef, HostBinding, HostListener} from "@angular/core";

@Directive({
   selector: '[appDropdown]'
})
export class DropdownDirective{
   constructor(private elementRef: ElementRef){

   }
   
   @HostListener('document:click', ['$event']) private onClick(){
      // this.isOpen = !this.isOpen; This only works if you click inside the button
      this.isOpen = this.elementRef.nativeElement.contains(event.target) ? !this.isOpen : false
   }

   @HostBinding('class.open') private isOpen = false;
   
}